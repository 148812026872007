<template>
  <div class="login-container dk:pb-14">
    <div class="banner">
      <div class="banner-text">
        <div class="container">
          <h2 class="banner-title">ASCVTS 2024</h2>
          <h2 class="banner-sub-title">Sign up</h2>
        </div>
      </div>
    </div>
    <a-row :gutter="{xs: 0, sm: 0, md: 20, lg: 20, xl: 20, xxl: 20}" class="login-content">
      <a-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16" :xxl="16">
        <div class="login-main">
          <!-- <img src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/2023/12/15/4629478cbf8042d2b2127aefc52a717d/login_welcome_1.png" alt=""> -->
          <div class="text-box">
            <h4>Registration for ASCVTS 2024 is now open!</h4>
            <h6>Ⅰ.Registration Fee</h6>
            <table>
              <!-- <tr style="height:30.5pt;background: #f1f5fd"> -->
              <tr style="height:30.5pt;">
                <th style="display: table-cell;vertical-align: middle;font-weight: bold;text-align: center;padding:6px;width: 12%">Early Bird Registration<br>April 15th 2024</th>
                <th style="display: table-cell;vertical-align: middle;font-weight: bold;text-align: center;padding:6px;width: 14%">Pre Registration<br>May 7th 2024</th>
                <th style="display: table-cell;vertical-align: middle;font-weight: bold;text-align: center;padding:6px;width: 13%">On Site Registration</th>
              </tr>
              <tr>
                <td style="display: table-cell;vertical-align: middle;text-align: center;padding:6px;">250 dollars</td>
                <td style="display: table-cell;vertical-align: middle;text-align: center;padding:6px;">450 dollars</td>
                <td style="display: table-cell;vertical-align: middle;text-align: center;padding:6px;">550 dollars</td>
              </tr>
            </table>
            <h6>Ⅱ.Cancellation and Refund Policy</h6>
            <table>
              <tr style="height:30.5pt;">
                <th style="display: table-cell;vertical-align: middle;font-weight: bold;text-align: center;padding:6px;width: 12%">Refund Application Date</th>
                <th style="display: table-cell;vertical-align: middle;font-weight: bold;text-align: center;padding:6px;width: 14%">Management Fee Deducted</th>
              </tr>
              <tr>
                <td style="display: table-cell;vertical-align: middle;text-align: center;padding:6px;">Before April 15th 2024 (Inclusive)</td>
                <td style="display: table-cell;vertical-align: middle;text-align: center;padding:6px;">0</td>
              </tr>
              <tr>
                <td style="display: table-cell;vertical-align: middle;text-align: center;padding:6px;">After April 16th 2024 (Inclusive)</td>
                <td style="display: table-cell;vertical-align: middle;text-align: center;padding:6px;">100% of the Registration Fee</td>
              </tr>
            </table>
          </div>
        </div>

      </a-col>
      <a-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" :xxl="8" class="sign-col">
        <div class="sign-main dk:p-5 mb:px-3 mb:py-5 mb:mt-3 mb:mb-6">
          <div class="title font-bold text-lg py-3">
            Sign up
          </div>
          <div class="pt-8 sign-btn">
            <a-button block @click="goPage('https://ascvts2024.sciconf.cn/en/user/register/21452')">
              New User Registration
            </a-button>
          </div>
          <div class="pt-8 sign-btn">
            <a-button block @click="goPage('https://ascvts2024.sciconf.cn/cn/user/login/21452')">
              境内代表请点击此处注册
            </a-button>
          </div>

          <div class="pt-12 login-btn">
            <a-button block @click="goPage('https://ascvts2024.sciconf.cn/en/user/login/21452')">
              Log In
            </a-button>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import {isLogin } from "@/utils/util";

  export default {
    name: "Login",
    data() {
      const validateEmail = (rule, value, callback) => {
        if (!value) {
          callback(new Error());
        } else if (!(/^[A-z0-9]+([-._][A-z0-9]+)*@([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,4}$/.test( value ) && /^(?=.{1,64}@.{4,64}$)(?=.{6,100}$).*/.test( value ))) {
          callback(new Error());
        } else {
          callback();
        }
      };
      return {
        form: {
          userId: '',
          password: ''
        },
        rules: {
          userId: [
            { required: true, validator: validateEmail, message: 'Please enter your user Id(E-mail).', trigger: 'blur' },
          ],
          password: [
            { required: true, message: 'Please enter your password.', trigger: 'blur' },
          ]
        },
        loginLoading: false,
      }
    },
    mounted() {
      if (isLogin()) {
        return this.$router.push({name: "home"})
      }
    },
    methods: {
      goPage(url){
        window.location.href = url
      }
    }
  }
</script>

<style lang="less" scoped>
.banner {
  position: relative;
  min-height: 300px;
  color: #fff;
  background-position: 50% 50%;
  background-size: cover;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1) ), url('../../assets/img/about/banner.png');
  .banner-text {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    z-index: 1;
    transform: translateY(-50%);
    .container {
      max-width: 1320px;
      margin: 0 auto;
      text-rendering: optimizeLegibility;
      -webkit-font-smoothing: antialiased !important;
      .banner-title {
        color: #e8bd55;
        text-transform: uppercase;
        font-size: 58px;
        font-weight: 900;
      }
      .banner-sub-title{
        color: #fff;
        font-size: 48px;
        font-weight: 900;
      }
      @media (max-width:1320px) {
        padding: 0 15px;
      }
      @media (max-width:768px) {
        .banner-title {
          font-size: 48px;
        }
        .banner-sub-title {
          font-size: 38px;
        }
      }
    }
  }
}
.login-container {

  .header-poster-content {
    & > div:first-child {
      color: #F8B301;
    }
    & > div:last-child {
      color: white;
    }
  }
  .login-main {
    //height: 380px;
    background-image:linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.4)), url(https://drvoicedev.oss-cn-beijing.aliyuncs.com/2024/01/31/832c481441754992b2be768df0d74f01/xz.png);
    // background-image:linear-gradient(to right, rgba(25, 49, 126, .99), rgba(25, 49, 126, 0.99)), url(https://drvoicedev.oss-cn-beijing.aliyuncs.com/2024/01/31/832c481441754992b2be768df0d74f01/xz.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .text-box {
      height: 100%;
      color: #fff;
      font-size: 30px;
      display: flex;
      padding: 20px 30px;
      flex-direction: column;
      h4 {
        color: #fff;
        line-height: 52px;
        font-size: 24px;
        border-bottom: 1px solid white;
        margin-bottom: 10px;
      }
      h6 {
        color: #fff;
        font-size: 16px;
        line-height: 32px;
        margin-bottom: 6px;
      }
      // p {
      //   font-size: 28px;
      // }
      table {
        display: table;
        width: 100%;
        border-collapse: collapse;
        tr {
          display: table-row;
          th {
            font-size: 18px;
            border: 1px solid #fff;
            color: #f8b301;
          }
          td {
            font-size: 16px;
            font-family: "Akrobat Regular";
            border: 1px solid #fff;
          }
        }
      }
    }
  }
  .sign-main {
    background: #19317E;
    height: 380px;
    .title {
      font-family: Akrobat;
      color: white;
      border-bottom: 1px solid white;
    }
    .sign-btn {
      button {
        background: #F8B301;
        border-color: #F8B301;
        color: #333333;
        font-family: Akrobat;
        font-weight: 800;
        &:hover {
          background: rgba(248, 179, 1, 0.8);
          border-color: rgba(248, 179, 1, 0.8);
        }
      }
    }
    .login-btn {
      button {
        background: #fff;
        border-color: #fff;
        color: #333333;
        font-family: Akrobat;
        font-weight: 800;
        &:hover {
          background: rgba(255, 255, 255, 0.8);
          border-color: rgba(255, 255, 255, 0.8);
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .login-content {
      width: 100%;
      margin: 0 auto;

    }
    .login-main {
      // display: none;
    }
    .sign-col {
      background: #19317E;
      .sign-main {
        margin: 0 auto;
        height: 380px;
        width: 350px;
      }
    }
  }
  @media screen and (min-width: 768px) {
    .login-content {
      padding-top: 20px;
    }
  }
  @media screen and (min-width: 820px) {
    .login-content {
      padding-top: 20px;
    }
  }
  @media screen and (min-width: 992px) {
    .login-content {
      padding-top: 20px;
      width: 900px;
      max-width: 900px;
      margin: 0 auto!important;
    }
    .sign-main {
      height: 380px;
    }
  }
  @media screen and (min-width: 1200px) {
    .login-content {
      padding-top: 20px;
      width: 1013px;
      max-width: 1013px;
      margin: 0 auto!important;
    }
    .login-main {
      height: 430px;
    }
    .sign-main {
      height: 430px;
    }
  }

}
.ant-col {
  min-height: 0;
}
</style>
